<template>
  <div class="manage" style="height: 100%">
    <el-dialog width="40%" :visible.sync="isShow" title="添加新的盘点信息">
      <zaikupandian-form :addForm="addForm" ref="stockFrom"></zaikupandian-form>
      <div slot="footer">
        <el-button @click="isShow = false"> 取消 </el-button>
        <el-button type="primary" @click="submitForm('stockFrom')"> 确认添加 </el-button>
      </div>
    </el-dialog>
    <el-container style="height: 100%">
      <el-header>
        <div class="left">
          <el-button type="primary" @click="addBack"> 添加新的盘点信息 </el-button>
          <el-button type="primary"> 导出数据 </el-button>
        </div>
        <div class="right">
          <el-input placeholder="盘点标题 | 库房 | 时间" v-model="need" @keyup.enter.native="getTableData(need)">
            <el-button slot="append" @click="getTableData(need)"> 查询 </el-button>
          </el-input>
        </div>
      </el-header>
      <el-main style="height: 100%">
        <zaikupandian-table :dataList="tableData" :config="config" style="height: 100%" @changePage="getTableData"></zaikupandian-table>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import ZaikupandianForm from './zaikupandianForm'
import ZaikupandianTable from './zaikupandianTable'
import utils from '../../../assets/js/utils'
export default {
  components: {
    ZaikupandianForm,
    ZaikupandianTable
  },
  data() {
    return {
      isShow: false,
      usertype: '',
      user_realname: '',
      need: '',
      tableData: [],
      addForm: {
        title: '',
        pandiankufang: '',
        pandianshijian: '',
        added_person: '',
        added_person_time: '',
        date: ''
      },
      config: {
        page: 1,
        total: 0,
        loading: false
      }
    }
  },
  created() {
    this.getTableData('')
  },
  methods: {
    addBack() {
      //让表单显示出来
      this.isShow = true
    },
    submitForm(formName) {
      let form = this.$refs[formName]
      form.submitForm()
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      if (form.isValidationOk) {
        this.addForm.added_person = this.user_realname
        this.addForm.pandianren = this.user_realname
        this.addForm.added_person_time = utils.getDateTime()
        console.log('addFormData:', this.addForm)

        this.$axios.post('/addZaikupandian/', this.addForm).then(res => {
          if (res.data.code === '1000') {
            this.$message({
              type: 'success',
              message: '增加成功!',
              duration: 2000
            })
            this.isShow = false
            this.getTableData('')
          } else {
            this.$message({
              type: 'warning',
              message: res.data.msg,
              duration: 2000
            })
          }
        })
      }
    },

    getTableData(find) {
      this.config.loading = true
      console.log('find:', find)
      const api = '/getZaikupandians/'
      this.$axios
        .get(api, {
          params: {
            page: this.config.page,
            keyword: find
          }
        })
        .then(res => {
          if (res.data.code === '1000') {
            this.config.total = res.data.totalcount
            this.tableData = res.data.data.map(item => {
              item.added = item.added_person + '\n' + item.added_person_time
              return item
            })
          } else {
            console.log('没有在库盘点信息！')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
      this.config.loading = false
    }
  }
}
</script>

<style scoped>
.inline {
  display: inline;
}
.el-main {
  padding-top: 0;
}
.right {
  float: right;
}
.left {
  float: left;
}
</style>
