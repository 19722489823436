<template>
  <!--有些地方没有办法填入数据是因为没有绑定变量-->
  <el-form size="small" label-position="top" :rules="rules" ref="thisstockfrom" :model="addForm">
    <el-form-item label="盘点标题" prop="pandianbiaoti">
      <el-input v-model="addForm.pandianbiaoti" type="textarea"></el-input>
    </el-form-item>
    <el-form-item label="盘点库房" prop="pandiankufang">
      <el-select placeholder="请选择库房" filterable v-model="addForm.pandiankufang">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="盘点时间" prop="pandianshijian">
      <el-date-picker
        value-format="yyyy-MM-dd"
        v-model="addForm.pandianshijian"
        type="date"
        placeholder="选择出库日期"
        :picker-options="pickerOptions1"
      ></el-date-picker>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'stockFrom',
  props: {
    addForm: Object,
    type: Boolean
  },
  data() {
    return {
      rules: {
        pandianbiaoti: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        pandiankufang: [{ required: true, message: '请选择盘点库房', trigger: 'blur' }],
        pandianshijian: [{ required: true, message: '请选择盘点时间', trigger: 'blur' }]
      },
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      isValidationOk: false,
      options: [
        {
          //选项内容
          value: '1',
          label: '安徽库房'
        },
        {
          value: '2',
          label: '郑州库房'
        },
        {
          value: '3',
          label: '河北库房'
        },
        {
          value: '4',
          label: '余杭区库房'
        },
        {
          value: '5',
          label: '南京公司库房'
        },
        {
          value: '6',
          label: '吉林四平库房'
        },
        {
          value: '7',
          label: '山东淄博库房'
        },
        {
          value: '8',
          label: '沈阳公司库房'
        },
        {
          value: '9',
          label: '营口公司库房'
        },
        {
          value: '10',
          label: '山东青岛库房'
        },
        {
          value: '11',
          label: '徐州库房'
        },
        {
          value: '12',
          label: '西安库房'
        },
        {
          value: '13',
          label: '长沙库房'
        },
        {
          value: '14',
          label: '福州库房'
        }
      ]
    }
  },
  methods: {
    submitForm() {
      this.$refs['thisstockfrom'].validate(valid => {
        if (valid) {
          this.isValidationOk = true
        } else {
          this.isValidationOk = false
          return false
        }
      })
    }
  }
}
</script>

<style scoped></style>
