<template>
  <div class="common-table" style="height: 100%;">
    <el-table
      v-loading="config.loading"
      :data="dataList"
      height="90%"
      stripe
      border
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
    >
      <el-table-column type="selection" width="40"></el-table-column>
      <el-table-column label="盘点标题" prop="pandianbiaoti" sortable> </el-table-column>
      <el-table-column label="盘点库房" prop="pandiankufang" width="80"> </el-table-column>
      <el-table-column label="盘点时间" prop="pandianshijian" width="120"> </el-table-column>
      <el-table-column label="盘点人" prop="pandianren" width="95"> </el-table-column>
      <el-table-column label="完成状态" prop="wanchengzhuangtai" width="90">
        <el-button @click="pandianFinish(scope.row)" slot-scope="scope" type="success" size="mini">
          {{ scope.row.wanchengzhuangtai ? '已完成' : '未完成' }}
        </el-button>
      </el-table-column>
      <el-table-column label="负责人" prop="wanchengfuze" width="85"> </el-table-column>
      <el-table-column label="库房确认" prop="kuguanqueren" width="90">
        <el-button @click="pandianKufangqueren(scope.row)" slot-scope="scope" type="success" size="mini">
          {{ scope.row.kuguanqueren ? '已确认' : '未确认' }}
        </el-button>
      </el-table-column>
      <el-table-column label="确认时间" prop="querendate" width="100"> </el-table-column>
      <el-table-column label="管理" width="65">
        <template slot-scope="scope">
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteTableRow(scope.$index, scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :page-size="pagesize" layout="prev, pager, next" :total="config.total" :current-page.sync="config.page" @click="changePage"></el-pagination>
  </div>
</template>

<script>
import utils from '../../../assets/js/utils'

export default {
  props: {
    //接收的参数和参数类型
    dataList: Array,
    config: Object
  },
  data() {
    return {
      pagesize: 20
    }
  },
  methods: {
    //换页
    changePage(page) {
      this.$emit('changePage', page)
    },
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    },
    pandianKufangqueren(row) {
      this.$confirm('是否库房确认？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {
            id: row.id,
            querendate: utils.getDateTime()
          }
          this.$axios.put('/pandianKufangqueren/', params).then(res => {
            if (res.data.code === '1000') {
              this.$message({
                type: 'success',
                message: '确认成功！'
              })
              row.jieshouqueren_state = true
            } else {
              this.$message({
                type: 'success',
                message: '确认失败！'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消确认'
          })
        })
    },
    pandianFinish(row) {
      this.$confirm('是否确认盘点完成？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {
            id: row.id,
            wanchengfuze: utils.getUserCookie().name
          }
          this.$axios.put('/pandianFinish/', params).then(res => {
            if (res.data.code === '1000') {
              this.$message({
                type: 'success',
                message: '确认成功！'
              })
              row.wanchengzhuangtai = true
            } else {
              this.$message({
                type: 'success',
                message: '确认失败！'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消确认'
          })
        })
    },
    deleteTableRow(index, row) {
      this.$confirm('此操作将删除该库房盘点信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/deleteZaikupandian/?id=' + row.id).then(res => {
          console.log(res.data)
          if (res.data === '删除成功！') {
            this.$message({
              type: 'success',
              message: '删除成功！'
            })
            this.dataList.splice(index, 1)
          } else {
            this.$message({
              message: '删除失败，此信息不存在或已被删除'
            })
          }
        })
      })
    }
  }
}
</script>

<style scoped></style>
